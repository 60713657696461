<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
<template>
  <div v-if="lvl == 1" class="ma-0 pa-0">
    <v-card class="pa-4 ma-3 text-left" flat>
      <v-row dense>
        <v-col cols="3" >

          <v-row dense>
            <v-col cols="12" class="title mb-5 font-weight-bold">
              펀딩 안내 이미지<br/>
              <span class="body-2"></span><br/>
            </v-col>
          </v-row>

        </v-col>
        <v-col cols="9">
          <v-file-input
            v-model="img_file1"
            label="이미지 선택"
            @change="change_img(0)"
          >
          </v-file-input>
          <v-btn class="ma-2" left color="#FBDE44FF" @click="upload_img_to_s3">업로드</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-4 ma-3 text-left" flat>
      <v-row dense>
        <v-col cols="3" >

          <v-row dense>
            <v-col cols="12" class="title mb-5 font-weight-bold">
              펀딩 요청 목록<br/>
              <span class="body-2"></span><br/>
            </v-col>
          </v-row>

        </v-col>
        <v-col cols="9">

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    이메일
                  </th>
                  <th class="text-left">
                    예금주
                  </th>
                  <th class="text-left">
                    금액
                  </th>
                  <th class="text-left">
                    날짜
                  </th>
                  <th class="text-left">
                    성함
                  </th>
                  <th class="text-left">
                    연락처
                  </th>
                  <th class="text-left">
                    강의권 신청
                  </th>
                  <th class="text-left">
                    강의권 주소
                  </th>
                  <th class="text-left">
                    컨설팅 신청
                  </th>
                  <th class="text-left">
                    확인
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in fund_request_list"
                  v-if="item.confirm != true"
                  :key="item.name"
                >
                  <td>{{ item.email }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.amount }}원</td>
                  <td>{{ item.date }}</td>
                  <td>{{ item.rname }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.lecture }}</td>
                  <td>{{ item.lecture_address }}</td>
                  <td>{{ item.consult }}</td>
                  <td>
                    <v-btn
                      @click="confirm(item)"
                      >
                      확인
                    </v-btn>
                    <v-btn
                      @click="del(item)"
                      >
                      삭제
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-4 ma-3 text-left" flat>
      <v-row dense>
        <v-col cols="3" >

          <v-row dense>
            <v-col cols="12" class="title mb-5 font-weight-bold">
              펀딩 목록<br/>
              <span class="body-2"></span><br/>
            </v-col>
          </v-row>

        </v-col>
        <v-col cols="9">

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    이메일
                  </th>
                  <th class="text-left">
                    예금주
                  </th>
                  <th class="text-left">
                    금액
                  </th>
                  <th class="text-left">
                    날짜
                  </th>
                  <th class="text-left">
                    성함
                  </th>
                  <th class="text-left">
                    연락처
                  </th>
                  <th class="text-left">
                    강의권 신청
                  </th>
                  <th class="text-left">
                    강의권 주소
                  </th>
                  <th class="text-left">
                    컨설팅 신청
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in fund_list"
                  :key="item.name"
                >
                  <td>{{ item.email }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.amount }}원</td>
                  <td>{{ item.date }}</td>
                  <td>{{ item.rname }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.lecture }}</td>
                  <td>{{ item.lecture_address }}</td>
                  <td>{{ item.consult }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-col>
      </v-row>
    </v-card>
    <div>
      <img src="https://wintersleepingfunddesc.s3.ap-northeast-2.amazonaws.com/instruction.png" />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app'
import 'firebase/auth'
import axios from 'axios'
import { mapGetters } from 'vuex'


import S3 from 'aws-sdk/clients/s3'
import AWS from 'aws-sdk/global';

import api_conf from '../../config/config.json'

export default {
  props: {
    msg: String
  },
  data: () => {
    return {
      fund_list :[],
      fund_request_list : [],
      img_src: '',
      img_file1: null,
      check_upload: false,
      temp_file_list: [],
      // ---- env 파일에 숨길 내용 -----
      albumBucketName: 'wintersleepingfunddesc',
      bucketRegion: 'ap-northeast-2',
      IdentityPoolId: 'ap-northeast-2:cfe60094-7345-4dfd-b894-cca426e3b67c',
      albumName: 'test-album'
      // -------------------------------
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      lvl: "lvl",
    })
  },
  created: async function() {
    // this.loading = true;
  },
  mounted: async function() {
    this.$nextTick(async function() {
      await this.reload_info();
      this.get_img_from_s3();
    });
  },
  watch: {
    // dialog (val) {
    //   val || this.close()
    // },
  },
  methods: {
    connect_s3() {
      AWS.config.update({
        region: this.bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: this.IdentityPoolId
        })
      })
      const s3 = new S3({
        apiVersion: "2006-03-01",
        params: { Bucket: this.albumBucketName }
      })
      return s3
    },
    change_img: function(idx) {
      if ( this.img_file1 != undefined && idx == 0 ){
        this.temp_file_list[idx] = this.img_file1
      }
      this.check_upload = false
    },
    get_img_from_s3 () {
      const s3 = this.connect_s3()
      s3.listObjects({
        Delimiter: "/"
      }, (err, data) => {
        if (err ) {
          return alert('There was an error listing your albums: ' + err.message)
        } else {
          console.log(data)
        }
      })
    },
    upload_img_to_s3 () {
      if ( !this.img_file1 ) {
        return alert('Please choose a file to upload first')
      }
      const s3 = this.connect_s3()
      let albumPhotosKey = "/"
      for (let each in this.temp_file_list ) {
        let photoKey = "instruction.png"
        let upload = new S3.ManagedUpload({
          params: {
            Bucket: this.albumBucketName,
            Key: photoKey,
            Body: this.temp_file_list[each],
            ACL: 'public-read'
          }
        })
        let promise = upload.promise()
        promise.then((data) => {
          console.log(data);
          // 처리할게 일단 없음
        }, (err) => {
          console.log(err)
          return alert("There was an error uploading your photo: ", err.message);
        })
      }
      this.check_upload = true
    },
    reload_info: async function() {
      if ( firebase.auth().currentUser ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        const fund_list = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/fund/all', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        if( fund_list.data.length > 0 ) {
          this.fund_list = fund_list.data
        }

        const fund_request_list = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/fund/request/all', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        if( fund_request_list.data.length > 0 ) {
          this.fund_request_list = fund_request_list.data
        }
      } else {
        setTimeout(() => {
          this.reload_info();
        }, 500);
      }

    },
    confirm: async function(item) {
      if(confirm("계좌에서 확인하셨습니까? 확인 하신 경우에만 눌러주세요.")) {
        console.log(item);
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.post(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/fund/', {
          email: this.user.data.email,
          t_email: item.email,
          amount: item.amount,
          name: item.name,
          date: item.date,
          rname: item.rname,
          phone: item.phone,
          lecture: item.lecture,
          lecture_address: item.lecture_address,
          fund_request_id: item._id,
        }, {
          headers: {'id_token': id_token},
        });
        this.reload_info();
      }
    },
    del: async function(item) {
      if(confirm("삭제하시겠습니까?")) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.post(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/fund/request/delete/', {
          email: this.user.data.email,
          fund_request_id: item._id,
        }, {
          headers: {'id_token': id_token},
        });
        this.reload_info();
      }
    }
  }
}
</script>

<style scoped>

</style>
